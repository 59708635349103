<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- Breadcrumb -->
                <div class="position-fixed w-100" style="top:0;z-index:99;transform:translateX(-1px);">
                    <div class="d-flex mb-4 pt-3 pb-3 border-bottom align-items-center bg-white">
                        <a href="javascript:history.back()"><span class="fe fe-arrow-left mr-1"></span>Kembali</a>
                        <span class="text-muted mx-2">|</span>
                        <div class="text-muted">Data Registrasi Ulang</div>
                        <span class="text-muted mx-2"><span class="fe fe-arrow-right-circle mr-1 text-sm"></span></span>
                        <div class="text-muted">{{ detailsetformulir.nama_formulir }}</div>
                    </div>
                </div>
                <!-- -->
                <div class="d-block mb-4 pt-5 mt-3">
                    <div class="d-flex align-items-center">
                        <div class="mr-3">
                            <img src="https://scolacdn.com/frontend/argonne-img/user-icon-teacher.png" width="38">
                        </div>
                        <div>
                            <h1 class="header-title">
                                Data Registrasi Ulang
                            </h1>
                            <div class="d-block">
                                Menu ini digunakan untuk mengelola registrasi peserta.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm">
                    <!-- Card Header -->
                    <div class="d-flex align-items-center justify-content-between px-4 py-3 border-bottom">
                        <div class="d-flex align-items-center">
                            <div class="mr-4 border-right pr-4"><span class="small">Kode Formulir:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.kode_formulir }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Unit:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.unit_title }}</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Sub-unit:</span>
                                <div v-if="detailsetformulir.sub_unit_title" class="d-block font-weight-bold text-sm">{{ detailsetformulir.sub_unit_title }}</div>
                                <div v-else class="d-block font-weight-bold text-sm">-</div>
                            </div>
                            <div class="mr-4 border-right pr-4"><span class="small">Periode:</span>
                                <div class="d-block font-weight-bold text-sm">{{ detailsetformulir.nama_periode }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <div class="mr-3 text-right">
                                    <span class="small">Peserta</span>
                                    <div class="d-block font-weight-bold">{{ dataRegister.total_data }} siswa</div>
                                </div>
                                <div class="avatar-sm rounded-lg bg-primary-soft">
                                    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <span class="fe fe-user h3 m-0 text-primary"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <div class="card-body">
                        <div class="d-block border-bottom pb-3">
                            <div class="d-flex align-items-center mb-3">
                                <div class="avatar bg-primary mr-3 rounded-circle mt-n1">
                                    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <span class="fe fe-file h3 m-0 text-white"></span>
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <div class="h2 mb-1">{{ detailsetformulir.nama_formulir }}</div>
                                    <div class="d-block">Gelombang <span class="fe fe-chevron-right mx-1 text-muted"></span> {{ detailsetformulir.nama_gelombang }}</div>
                                </div>
                            </div>
                            <div class="text-sm d-block px-3 py-2 bg-light rounded">
                                <table>
                                    <tr>
                                        <td class="pr-2">
                                            <span class="fe fe-info mr-2 text-muted"></span>Ket:
                                        </td>
                                        <td v-if="detailsetformulir.keterangan">{{ detailsetformulir.keterangan }}</td>
                                        <td v-else>-</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="d-block p-4 border-bottom">
                                <div class="row align-items-center">
                                    <div class="col-md-3 pr-md-0">
                                        <select class="form-control custom-select form-control-rounded shadow-sm text-sm" v-model="type_formulir" v-on:change="getData(true)">
                                            <option value="">Lihat Semua Asal Sekolah</option>
                                            <option v-for="row in dataTypeRegister" :key="row.title">{{row.title}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2 pr-md-0">
                                        <select class="form-control custom-select form-control-rounded shadow-sm text-sm" v-model="type_kategori" v-on:change="getData()">
                                            <option value="">Lihat Semua Kategori</option>
                                            <option v-for="row in listKategori" :key="row.id" :value="row.id">{{row.kategori}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-5 pr-md-0">
                                        <!-- Search -->
                                        <form>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text text-sm">
                                                        <i class="fe fe-search"></i>
                                                    </span>
                                                </div>
                                                <input type="search" class="form-control text-sm search" v-model="keywords" v-on:keyup.enter="getData(true)" placeholder="Cari data berdasarkan kode registrasi/nama/email lalu tekan enter...">
                                            </div>
                                        </form>
                                    </div>
                                    <div class="d-none col-md-2">
                                        <button class="btn btn-block btn-outline-primary text-sm"><span class="fe fe-download-cloud mr-2"></span>Export</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-hover table-striped card-table">
                                    <thead>
                                        <tr>
                                            <th class="text-center pr-0">No</th>
                                            <th>Kode registrasi</th>
                                            <th class="w-60">Nama</th>
                                            <th>Email</th>
                                            <th>phone</th>
                                            <th>Kategori</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataRegister.length == 0 && !fetching">
                                        <tr>
                                            <td colspan="7">
                                                <div class="alert alert-light m-0" role="alert">
                                                    <div class="d-flex align-items-center">
                                                        <div>Data tidak tersedia..</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="7">
                                                <div class="alert alert-light m-0" role="alert">
                                                    <div class="d-flex align-items-center">
                                                        <div class="spinner-border spinner-border-sm mr-3" role="status"></div>
                                                        <div>Sedang mengambil data..</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching" class="list">
                                        <tr v-for="(register, index) in dataRegister" :key="index">
                                            <td class="text-center pr-0">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>
                                                {{ register.kode_registrasi }}
                                            </td>
                                            <td>
                                                {{ register.fullname }}
                                            </td>
                                            <td>
                                                {{ register.email }}
                                            </td>
                                            <td>
                                                {{ register.phone }}
                                            </td>
                                            <td>
                                                {{ register.kategori }}
                                            </td>
                                            <td class="orders-status">
                                                <router-link :to="{ name:'registrasi.detail',params: { id: register.id }}" class="btn btn-primary btn-sm"><i class="fe fe-eye mr-2"></i>Lihat</router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Jalankan fungsi empty state -->
                            <!-- <div v-if="dataRegister.length==0 && !fetching">
                                       <div class="d-block p-4">
                                            <div class="alert alert-light m-0" role="alert">
                                                Data tidak tersedia
                                            </div>
                                       </div>
                                    </div> -->
                            <div class="card-footer">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="changePage()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        components: {
            vPagination
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataRegister: [],
                listKategori: [],
                dataTypeRegister: [],
                detailsetformulir: {
                    kode_formulir: '',
                    nama_formulir: '',
                    unit_title: '',
                    sub_unit_title: '',
                    jadwal_tes: '',
                    url_tes: '',
                    informasi_tes: '',
                    payment_gateway_information: '',
                    external_gateway_information: '',
                    informasi_reg_ulang: '',
                    countsiswa: ''
                },
                type_kategori: '',
                fetching: true,
                type_formulir: '',
                keywords: '',
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 5,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
            }
        },
        created() {
            if (localStorage.getItem("page_re_registration_detail") !== null) {
                this.pagination.currentPage = parseInt(localStorage.getItem("page_re_registration_detail"));
            }
            this.getData();
            this.getDataTypeRegistrasi();
            this.getDataFormulir();
            this.getKategori();
        },
        methods: {
            // CHANGE PAGE PAGINATION
            changePage() {
                localStorage.setItem("page_re_registration_detail", this.pagination.currentPage);
                this.getData();
            },
            // GET DATA REGISTER
            getData(reset = false) {
                if (reset) {
                    this.pagination.currentPage = 1;
                    this.pagination.totalPages = 0;
                    localStorage.setItem("page_re_registration_detail", this.pagination.currentPage);
                }
                this.fetching = true;
                this.dataRegister = [];
                this.$http.get(this.baseUrl + `admin/registrasi-ulang/data/setupformulir?id=${this.$route.params.id}`, {
                        params: {
                            setup_formulir_id: this.$route.params.id,
                            type_formulir: this.type_formulir,
                            kategori: this.type_kategori,
                            keywords: this.keywords,
                            page: this.pagination.currentPage,
                        }
                    })
                    .then((response) => {
                        if (response.data.status) {
                            this.dataRegister = response.data.data;
                        }
                        this.dataRegister.total_data = response.data.total_data;
                        this.pagination.totalPages = response.data.total_page;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getDataTypeRegistrasi() {
                // GET DATA REGISTER
                this.dataTypeRegister = [];
                this.$http.get(this.baseUrl + `admin/gelombang/detail/type_formulir`)
                    .then((response) => {
                        this.dataTypeRegister = response.data.data;
                        if (response.data.status == false) {
                            this.dataTypeRegister = []
                        }
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    })
            },
            // GET DATA detailsetformulir
            getDataFormulir() {
                this.$http.get(this.baseUrl + `admin/gelombang/detail/formulir?id=${this.$route.params.id}`)
                    .then(response => {
                        this.detailsetformulir.nama_gelombang = response.data.data[0].nama_gelombang;
                        this.detailsetformulir.nama_periode = response.data.data[0].nama_periode;
                        this.detailsetformulir.nama_formulir = response.data.data[0].nama_formulir;
                        this.detailsetformulir.kode_formulir = response.data.data[0].kode_formulir;
                        this.detailsetformulir.unit_title = response.data.data[0].unit_title;
                        this.detailsetformulir.sub_unit_title = response.data.data[0].sub_unit_title;
                        this.detailsetformulir.jadwal_tes = response.data.data[0].jadwal_tes;
                        this.detailsetformulir.url_tes = response.data.data[0].url_tes;
                        this.detailsetformulir.payment_gateway_information = response.data.data[0].payment_gateway_information;
                        this.detailsetformulir.external_gateway_information = response.data.data[0].external_gateway_information;
                        this.detailsetformulir.informasi_tes = response.data.data[0].informasi_tes;
                        this.detailsetformulir.informasi_reg_ulang = response.data.data[0].informasi_reg_ulang;
                        this.detailsetformulir.deskripsi_formulir = response.data.data[0].deskripsi_formulir;
                        this.detailsetformulir.deskripsi_lulus = response.data.data[0].deskripsi_lulus;
                        this.detailsetformulir.deskripsi_tidak_lulus = response.data.data[0].deskripsi_tidak_lulus;
                        this.detailsetformulir.countsiswa = response.data.data[0].countsiswa;
                        this.detailsetformulir.status = response.data.data[0].status;
                    })
                    .catch(() => {
                        this.fetching = false;
                    });
            },
            getKategori() {
                this.listKategori = [];
                this.$http.get(this.baseUrl + `admin/registrasi/data/kategori`)
                    .then((response) => {
                        this.listKategori = response.data.data;
                        this.fetching = false;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            }
        }
    }
</script>